import type { ReactNode } from "react";

interface TutorialParentProps {
    children: ReactNode;
}

export const TutorialParent = ({ children }: TutorialParentProps) => {
    return (
        <div className="overflow-x-hidden flex flex-col lg:flex-row-reverse items-center justify-center px-4 gap-12 my-20 text-black dark:text-white">
            {children}
        </div>
    );
};
